import { BrowserModule } from '@angular/platform-browser';
import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TagInputModule } from 'ngx-chips';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { CandidateFormComponent } from './candidate-form/candidate-form.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LoginComponent } from './login/login.component';
import { CandidateListComponent } from './candidate-list/candidate-list.component';
import { ClientListComponent } from './client-list/client-list.component';
import { EmailTemplateComponent } from './email-template/email-template.component';
import { EmailTemplateListComponent } from './email-template-list/email-template-list.component';

@NgModule({
  declarations: [
    AppComponent,
	LoginComponent,
    CandidateFormComponent,
    HeaderComponent,
    FooterComponent,
    CandidateListComponent,
    ClientListComponent,
    EmailTemplateComponent,
    EmailTemplateListComponent
  ],
  imports: [
    BrowserModule,
  FormsModule,
  TagInputModule,
  AppRoutingModule,
  HttpClientModule
	
	
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
