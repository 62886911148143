import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { Globals } from '.././globals';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient } from "@angular/common/http";


@Injectable()
export class AuthService {

	constructor(private http: HttpClient, private globals: Globals, private router: Router) { }

	login(loginEntity) {
		debugger
		let jwtHelper = new JwtHelperService();
		let promise = new Promise((resolve, reject) => {
			this.http.post(this.globals.baseAPIUrl + 'Login/check_login', loginEntity)
				.toPromise()
				.then(
					res => { // Success 
						let result = res;
						if (result && result['token']) {
							localStorage.setItem('token', result['token']);
							this.globals.authData = new JwtHelperService().decodeToken(result['token']);
						}
						resolve(res);
					},
					msg => { // Error
						reject(msg);
						this.globals.isLoading = false;
					}
				);
		});
		return promise;
	}

	logout(panel) {
		let promise = new Promise((resolve, reject) => {
			this.http.post(this.globals.baseAPIUrl + 'Login/logout', panel)
				.toPromise()
				.then(
					res => { // Success
						this.globals.authData = '';
						localStorage.removeItem('token');
						resolve(res);
					},
					msg => { // Error
						reject(msg);

						// this.router.navigate(['/pagenotfound']);
					}
				);
		});
		return promise;
	}

	isLoggedIn() {
		
		let jwtHelper = new JwtHelperService();
		let token = localStorage.getItem('token');
		if (!token) {
			return false;
		} else {
			return true;
		}
		// let isExpired = jwtHelper.isTokenExpired(token) ? true : false;	 
		// return !isExpired;
	}

	// db_mode(){  
	// 	let promise = new Promise((resolve, reject) => {
	// 		this.http.get(this.globals.baseAPIUrl + 'Login_user/db_mode', this.globals.headerpath)
	// 			.toPromise()
	// 			.then( 
	// 				res => { // Success 
	// 					resolve(res.json());
	// 				},
	// 				msg => { // Error
	// 					reject(msg.json());

	// 					this.router.navigate(['/pagenotfound']);
	// 				}
	// 			);
	// 	});		
	// 	return promise;
	// 	}

}
