import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl, NgModel } from '@angular/forms';
import { Globals } from '.././globals';
import { Router } from '@angular/router';
import { throwError, of, empty } from 'rxjs';
import { CandidateFormService } from '../services/candidate-form.service';
import { Alert } from 'selenium-webdriver';

import { TagInputComponent as SourceTagInput } from 'ngx-chips';
declare var $, PerfectScrollbar, paypal, html2pdf, swal, getAccordion: any;


@Component({
  selector: 'app-candidate-list',
  templateUrl: './candidate-list.component.html',
  styleUrls: ['./candidate-list.component.css']
})
export class CandidateListComponent implements OnInit {
  @ViewChild('tagInput')
  tagInput: SourceTagInput;
  candidateList;
  ClientEntity;
  submitted;
  salary_valid;
  salary1_valid;
  PayRate_valid;
  To_valid;
  btn_disable;
  submitted1;
  CandidatePdfEntity;
  // @ViewChild('content') content:ElementRef;
  constructor(public globals: Globals, private router: Router, private elem: ElementRef, private CandidateFormService: CandidateFormService) { }

  ngOnInit() {
    this.ClientEntity = {};
    this.CandidatePdfEntity = {};
    this.ClientEntity.ClientId = 0;


    new PerfectScrollbar('.content_height');
    $("#printrecruiter").hide();

    $('input[type="radio"]').click(function () {
      var inputValue = $(this).attr("value");
      var targetBox = $("." + inputValue);
      $(".box").not(targetBox).css("display", "none");
      $(targetBox).css("display", "inline-block");
    });
    $('.salary_block').hide();
    $('#full_time input').click(function () {
      $('.position_type_block .col-md-4').removeClass("width_long");
      $('.position_type_block .col-md-4').addClass("width_small");
      $('#full_time').addClass("width_long");
      $('#full_time').removeClass("width_small");
      $('#contract').removeClass("width_long");
      $('#part_time').removeClass("width_long");
      $('#contract').addClass("width_small");
      $('#part_time').addClass("width_small");
      $('.salary_block').hide();
      $("#full_time .salary_block").fadeIn(700);
    });
    $('#part_time input').click(function () {
      $('.position_type_block .col-md-4').removeClass("width_long");
      $('.position_type_block .col-md-4').addClass("width_small");
      $('#part_time').addClass("width_long");
      $('#part_time').removeClass("width_small");
      $('#contract').removeClass("width_long");
      $('#full_time').removeClass("width_long");
      $('#contract').addClass("width_small");
      $('#full_time').addClass("width_small");
      $('.salary_block').hide();
      $('#part_time .salary_block').fadeIn(700);
    });
    $('#contract input').click(function () {
      $('.position_type_block .col-md-4').removeClass("width_long");
      $('.position_type_block .col-md-4').addClass("width_small");
      $('#contract').addClass("width_long");
      $('#contract').removeClass("width_small");
      $('#part_time').removeClass("width_long");
      $('#full_time').removeClass("width_long");
      $('#part_time').addClass("width_small");
      $('#full_time').addClass("width_small");
      $('.salary_block').hide();
      $('#contract .salary_block').fadeIn(700);
    });
    $('#addclientpopup .email_block').hide();
    $('#addclientpopup .modal-footer').hide();
    $('#addclientpopup #email_save').click(function () {
      $('#addclientpopup .email_block').fadeIn(700);
      $('#addclientpopup .modal-footer').fadeIn(700);
      $('#addclientpopup .button_hide').hide();
    });
    $('#addclientpopup .close_email').click(function () {
      $('#addclientpopup .email_block').hide();
      $('#addclientpopup .modal-footer').hide();
      $('#addclientpopup .button_hide').show();
    });
    $('#editclientpopup .email_block').hide();
    $('#editclientpopup .modal-footer').hide();
    $('#editclientpopup #email_save').click(function () {
      $('#editclientpopup .email_block').fadeIn(700);
      $('#editclientpopup .modal-footer').fadeIn(700);
      $('#editclientpopup .button_hide').hide();
    });
    $('#editclientpopup .close_email').click(function () {
      $('#editclientpopup .email_block').hide();
      $('#editclientpopup .modal-footer').hide();
      $('#editclientpopup .button_hide').show();
    });
    // $(document).ready(function() {
    //   $('[data-role="tags-input"]').tagsInput();
    // });
    this.CandidateFormService.getAllCandidate()
      .then((data) => {
        debugger
        this.candidateList = data;
        this.globals.isLoading = false;
        setTimeout(function () {
          var table = $('#candidate_tables').DataTable({
            //  scrollY: '55vh',
            responsive: {
              details: {
                display: $.fn.dataTable.Responsive.display.childRowImmediate,
                type: ''
              }
            },
            scrollCollapse: true,
            "oLanguage": {
              "sLengthMenu": "_MENU_ Candidates per page",
              "sInfo": "Showing _START_ to _END_ of _TOTAL_ Candidates",
              "sInfoFiltered": "(filtered from _MAX_ total Candidates)",
              "sInfoEmpty": "Showing 0 to 0 of 0 Candidates"
            },
            dom: 'lBfrtip',
            buttons: [
              {
                extend: 'excel',
                title: 'Recruitment – All Candidates – ',// + todaysdate,
                filename: 'Recruitment–AllCandidates–', // + todaysdate,
                exportOptions: {
                  columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
                },
                customize: function (xlsx) {
                  var source = xlsx.xl['workbook.xml'].getElementsByTagName('sheet')[0];
                  source.setAttribute('name', 'Recruitment-AllCandidates');
                },
              },
              {
                extend: 'print',
                title: 'Recruitment – All Candidates – ',//+ todaysdate
                exportOptions: {
                  columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
                }
              },
            ]
          });

          $(".buttons-print").append("<i class='fa fa-print'></i>");
          $('.buttons-print').attr('title', 'Print');
          $('.buttons-print').attr('data-toggle', 'tooltip');
          $('.buttons-print').attr('data-placement', 'top');
          $(".buttons-excel").append("<i class='fa fa-file-excel-o'></i>");
          $('.buttons-excel').attr('title', 'Export to Excel');
          $('.buttons-excel').attr('data-toggle', 'tooltip');
          $('.buttons-excel').attr('data-placement', 'top');
        }, 100);



      },
        (error) => {
          // this.globals.isLoading = false;
          this.router.navigate(['/pagenotfound']);
          this.globals.isLoading = false;

        });


  }
  public validators = [this.must_be_email.bind(this)];
  public errorMessages = {
    'must_be_email': 'Please be sure to use a valid email format'
  };
  public onAddedFunc = this.beforeAdd.bind(this);
  private addFirstAttemptFailed = false;
  private must_be_email(control: FormControl) {
    if (this.addFirstAttemptFailed && !this.validateEmail(control.value)) {
      return { "must_be_email": true };
    }
    return null;
  }
  private beforeAdd(tag: string) {

    if (!this.validateEmail(tag)) {
      if (!this.addFirstAttemptFailed) {
        this.addFirstAttemptFailed = true;
        this.tagInput.setInputValue(tag);
      }

      return throwError(this.errorMessages['must_be_email']);
      //return of('').pipe(tap(() => setTimeout(() => this.tagInput.setInputValue(tag))));

    }
    this.addFirstAttemptFailed = false;
    return of(tag);
  }
  private validateEmail(text: string) {
    var EMAIL_REGEXP = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/i;
    return (text && EMAIL_REGEXP.test(text));
  }
  getPDF(CandidateId) {
    debugger
    this.CandidatePdfEntity = {};
    this.CandidateFormService.CandidatePdf(CandidateId)
      .then((data) => {
        this.CandidatePdfEntity = data['Candidatename'];
        // alert(this.CandidatePdfEntity.PositionAppliedFor);
  
      },
        (error) => {
          swal({
            type: 'error',
            title: 'Oops...',
            text: "You can't delete this record because of their dependency!",
            showConfirmButton: false,
            timer: 1500
          })
        });
  }
 
  DeleteCandidate(Candidate) {
    swal({
      title: 'Delete a Candidate',
      text: "Are you sure you want to delete this candidate?",
      icon: "warning",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    })
      .then((result) => {
        if (result.value) {
          this.globals.isLoading = true;
          this.CandidateFormService.deleteCandidate(Candidate)
            .then((data) => {
              let index = this.candidateList.indexOf(Candidate);

              if (index != -1) {
                this.candidateList.splice(index, 1);
              }
              swal({

                type: 'success',
                title: 'Deleted!',
                text: 'Candidate has been deleted successfully',
                showConfirmButton: false,
                timer: 1500
              })
              this.globals.isLoading = false;
            },
              (error) => {
                swal({

                  type: 'error',
                  title: 'Oops!',
                  text: "You can't this record because of their dependency!",
                  showConfirmButton: false,
                  timer: 1500
                })
              });
        }
      })
  }
  addclient(candidate) {
    this.submitted = false;
    this.submitted1 = false;
    this.To_valid = false;

    $('#addclientpopup').modal('show');
    this.ClientEntity = {};
    this.ClientEntity.FirstName = candidate.FirstName;
    this.ClientEntity.LastName = candidate.LastName;
    this.ClientEntity.PositionAppliedFor = candidate.PositionAppliedFor;
    this.ClientEntity.PositionType = candidate.PositionType;
    this.ClientEntity.CandidateId = candidate.CandidateId;
    this.ClientEntity.UserId = this.globals.authData.UserId;
    this.ClientEntity.Salary = candidate.Salary;

    if (this.ClientEntity.PositionType == 1) {
      this.ClientEntity.FSalary = this.ClientEntity.Salary;
      setTimeout(function () {
        $('#full_time').addClass("width_long");
        $('#full_time').removeClass("width_small");
        $('#contract').removeClass("width_long");
        $('#part_time').removeClass("width_long");
        $('#part_time').addClass("width_small");
        $('#contract').addClass("width_small");
        $('.salary_block').hide();
        $("#full_time .salary_block").fadeIn(700);
      }, 100);
    } else if (this.ClientEntity.PositionType == 2) {
      this.ClientEntity.PSalary = this.ClientEntity.Salary;
      setTimeout(function () {

        $('#part_time').addClass("width_long");
        $('#part_time').removeClass("width_small");
        $('#contract').removeClass("width_long");
        $('#full_time').removeClass("width_long");
        $('#full_time').addClass("width_small");
        $('#contract').addClass("width_small");
        $('.salary_block').hide();
        $("#part_time .salary_block").fadeIn(200);
      }, 100);
    } else {
      this.ClientEntity.PayRate = this.ClientEntity.Salary;
      setTimeout(function () {
        $('#contract').addClass("width_long");
        $('#contract').removeClass("width_small");
        $('#part_time').removeClass("width_long");
        $('#full_time').removeClass("width_long");
        $('#full_time').addClass("width_small");
        $('#part_time').addClass("width_small");
        $('.salary_block').hide();
        $("#contract .salary_block").fadeIn(200);
      }, 100);
    }
  }
  addClientNew(ClientForm) {
    this.globals.isLoading = true;
    var count = 0;
    if (this.ClientEntity.PositionType == 1) {
      if (this.ClientEntity.FSalary != '' && this.ClientEntity.FSalary != undefined && this.ClientEntity.FSalary != null) {
        this.ClientEntity.Salary = this.ClientEntity.FSalary;
        this.salary_valid = false;
      }
      else {
        this.salary_valid = true;
        count = 1;
      }
    } else {
      this.salary_valid = false;
    }
    if (this.ClientEntity.PositionType == 2) {
      if (this.ClientEntity.PSalary != '' && this.ClientEntity.PSalary != undefined && this.ClientEntity.PSalary != null) {
        this.ClientEntity.Salary = this.ClientEntity.PSalary;
        this.salary1_valid = false;
      }
      else {
        this.salary1_valid = true;
        count = 1;
      }
    } else {
      this.salary1_valid = false;
    }
    if (this.ClientEntity.PositionType == 3) {
      if (this.ClientEntity.PayRate != '' && this.ClientEntity.PayRate != undefined && this.ClientEntity.PayRate != null) {
        this.ClientEntity.Salary = this.ClientEntity.PayRate;
        this.PayRate_valid = false;
      }
      else {
        this.PayRate_valid = true;
        count = 1;
      }
    } else {
      this.PayRate_valid = false;
    }
    this.submitted = true;
    this.ClientEntity.ClientId = 0;
    if (ClientForm.valid && count == 0) {

      this.CandidateFormService.addclientsingle(this.ClientEntity)
        .then((data) => {
          this.ClientEntity.ClientId = data;
          swal({
            type: 'success',
            title: 'Success!',
            text: 'Client has been added successfully',
            showConfirmButton: false,
            timer: 3000
          })
          $('#addclientpopup').modal('hide');
          this.globals.isLoading = false;
        },
          (error) => {
            this.btn_disable = true;
            this.submitted = false;
            this.globals.isLoading = false;
            ClientForm.form.markAsPristine();
          });

    } else {
      this.globals.isLoading = false;
    }
  }
  ClientNew(ClientForm) {
    this.globals.isLoading = true;
    this.submitted = true;
    var count = 0;
    if (this.ClientEntity.PositionType == 1) {
      if (this.ClientEntity.FSalary != '' && this.ClientEntity.FSalary != undefined && this.ClientEntity.FSalary != null) {
        this.ClientEntity.Salary = this.ClientEntity.FSalary;
        this.salary_valid = false;
      }
      else {
        this.salary_valid = true;
        count = 1;
      }
    } else {
      this.salary_valid = false;
    }
    if (this.ClientEntity.PositionType == 2) {
      if (this.ClientEntity.PSalary != '' && this.ClientEntity.PSalary != undefined && this.ClientEntity.PSalary != null) {
        this.ClientEntity.Salary = this.ClientEntity.PSalary;
        this.salary1_valid = false;
      }
      else {
        this.salary1_valid = true;
        count = 1;
      }
    } else {
      this.salary1_valid = false;
    }
    if (this.ClientEntity.PositionType == 3) {
      if (this.ClientEntity.PayRate != '' && this.ClientEntity.PayRate != undefined && this.ClientEntity.PayRate != null) {
        this.ClientEntity.Salary = this.ClientEntity.PayRate;
        this.PayRate_valid = false;
      }
      else {
        this.PayRate_valid = true;
        count = 1;
      }
    } else {
      this.PayRate_valid = false;
    }

    if (ClientForm.valid && count == 0) {


      $('#addclientpopup .email_block').fadeIn(700);
      $('#addclientpopup .modal-footer').fadeIn(700);
      $('#addclientpopup .button_hide').hide();

      this.btn_disable = true;
      this.submitted = false;
      this.globals.isLoading = false;
      ClientForm.form.markAsPristine();
    } else {
      this.globals.isLoading = false;
    }
  }
  addClientemail(ClientEmailForm) {
    debugger
    this.submitted1 = true;
    var count = 0;
    // this.ClientEntity.To = $("#fromemail").val();
    this.globals.isLoading = true;
    if (this.ClientEntity.To != '' && this.ClientEntity.To != undefined && this.ClientEntity.To != null) {
      this.To_valid = false;
    }
    else {
      this.To_valid = true;
      count = 1;
    }
    if (this.ClientEntity.activedetails == true) { this.ClientEntity.activedetails = true; } else { this.ClientEntity.activedetails = false; }
    if (this.ClientEntity.AttachResume == true) { this.ClientEntity.AttachResume = true; } else { this.ClientEntity.AttachResume = false; }
    this.ClientEntity.ClientId = 0;
    if (ClientEmailForm.valid && count == 0) {
      this.ClientEntity.To = this.ClientEntity.To.join();

      this.CandidateFormService.addclient(this.ClientEntity)
        .then((data) => {

          $('#addclientpopup').modal('hide');
          this.ClientEntity.ClientId = data;
          swal({
            type: 'success',
            title: 'Success!',
            text: 'sent email successfully',
            showConfirmButton: false,
            timer: 3000
          })
          this.globals.isLoading = false;
        },
          (error) => {
            this.btn_disable = true;
            this.submitted1 = false;
            this.globals.isLoading = false;
            ClientEmailForm.form.markAsPristine();
          });


    } else {
      this.globals.isLoading = false;
    }
  }
  onTextChange(event)
{

this.To_valid=false;
}
}
