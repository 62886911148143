import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Globals } from './globals';
import { FormsModule } from '@angular/forms';
import { CandidateFormComponent } from './candidate-form/candidate-form.component';
import { LoginComponent } from './login/login.component';
import { CandidateFormService } from './services/candidate-form.service';
import { AuthGuard } from './services/auth-guard.service';
import { AuthService } from './services/auth.service';
import { EmailTemplateService } from './services/email-template.service';
import { ActiveDeleteService } from './services/active-delete.service';
import { CandidateListComponent } from './candidate-list/candidate-list.component';
import { ClientListComponent } from './client-list/client-list.component';
import { EmailTemplateComponent } from './email-template/email-template.component';
import { EmailTemplateListComponent } from './email-template-list/email-template-list.component';
const routes: Routes = [	
   { path : 'login', component : LoginComponent ,canActivate: [AuthGuard] },
    { path : 'candidate-form', component : CandidateFormComponent ,canActivate: [AuthGuard] },
    { path : 'candidate-form/edit/:id', component : CandidateFormComponent ,canActivate: [AuthGuard] },
    { path : 'candidate-list', component : CandidateListComponent ,canActivate: [AuthGuard] },
    { path : 'client-list/:id', component : ClientListComponent ,canActivate: [AuthGuard] },
    { path : 'email-template', component : EmailTemplateComponent ,canActivate: [AuthGuard] },
    { path : 'email-template/edit/:id', component : EmailTemplateComponent ,canActivate: [AuthGuard] },
    { path : 'email-templatelist', component : EmailTemplateListComponent ,canActivate: [AuthGuard] },
    { path: '', redirectTo: 'login', pathMatch:'full'},
    { path: '**', redirectTo : 'login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [Globals,CandidateFormService,EmailTemplateService,AuthGuard,ActiveDeleteService,AuthService]
})
export class AppRoutingModule { }
