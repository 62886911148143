import { Component, OnInit } from '@angular/core';
import { Globals } from '.././globals';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { EmailTemplateService } from '../services/email-template.service';
import { ActiveDeleteService } from '../services/active-delete.service';
declare var $, CKEDITOR: any, swal: any, PerfectScrollbar;


@Component({
  selector: 'app-email-template',
  templateUrl: './email-template.component.html',
  styleUrls: ['./email-template.component.css']
})
export class EmailTemplateComponent implements OnInit {
  header;
  emailEntity;
  roleList;
  placeholderList;
  tokenList;
  submitted;
  btn_disable;
  des_valid;
  constructor(public globals: Globals, private router: Router, private route: ActivatedRoute,
    private EmailTemplateService: EmailTemplateService,private ActiveDeleteService: ActiveDeleteService) { }

  ngOnInit() {
    this.globals.isLoading = false;
    this.emailEntity = {};
    this.des_valid=false;
    $(document).ready(function () {
      const body = document.querySelector('body');
      body.style.setProperty('--screen-height', $(window).height() - 100 + "px");
    });
    setTimeout(function()
    {   

    new PerfectScrollbar('.content_height');
    CKEDITOR.replace('EmailBody', {
      height: '200',
      resize_enabled: 'false',
      resize_maxHeight: '300',
      resize_maxWidth: '948',
      resize_minHeight: '300',
      resize_minWidth: '948',
      //extraAllowedContent: 'style;*[id,rel](*){*}'
      extraAllowedContent: 'span;ul;li;table;td;style;*[id];*(*);*{*}'
    });
    CKEDITOR.on('instanceReady', function () {
      CKEDITOR.document.getById('contactList').on('dragstart', function (evt) {
        var target = evt.data.getTarget().getAscendant('div', true);
        CKEDITOR.plugins.clipboard.initDragDataTransfer(evt);
        var dataTransfer = evt.data.dataTransfer;
        dataTransfer.setData('text/html', '{' + target.getText() + '}');
      });
    });
  },100);
    let id = this.route.snapshot.paramMap.get('id');
    this.EmailTemplateService.getDefaultList()
			.then((data) => {
				this.roleList = data['role'];
				this.placeholderList = data['placeholder'];
				this.tokenList = data['token'];
				this.globals.isLoading = false;
			},
				(error) => {
					this.globals.isLoading = false;
					//this.router.navigate(['/pagenotfound']);
				});
		if (id) {
			this.header = 'Edit';
			this.EmailTemplateService.getById(id)
				.then((data) => {
					if (data != "") {
						this.emailEntity = data;
						if (this.emailEntity.IsActive == "0") {
							this.emailEntity.IsActive = false;
						} else {
							this.emailEntity.IsActive = true;
						}
						if (this.emailEntity.Cc == 0) {
							this.emailEntity.Cc = "";
						}
						if (this.emailEntity.Bcc == 0) {
							this.emailEntity.Bcc = "";
						}
						CKEDITOR.instances.EmailBody.setData(this.emailEntity.EmailBody);
						this.globals.isLoading = false;
						
					} else {
						this.router.navigate(['/dashboard']);
					}

				},
					(error) => {
            this.globals.isLoading = false;
            this.router.navigate(['/dashboard']);
						//this.router.navigate(['/admin/pagenotfound']);
					});
		} else {
			this.header = 'Add';
			this.emailEntity = {};
			this.emailEntity.EmailId = 0;
			this.emailEntity.TokenId = '';
			this.emailEntity.To = '';
			this.emailEntity.Cc = '';
			this.emailEntity.Bcc = '';
			this.emailEntity.IsActive = '1';
			this.globals.isLoading = false;
			
  }

}
addEmailTemplate(EmailForm) {
  debugger
  this.emailEntity.EmailBody = CKEDITOR.instances.EmailBody.getData();
  if (this.emailEntity.EmailBody != "") {
    this.des_valid = false;
  } else {
    this.des_valid = true;
  }
  let id = this.route.snapshot.paramMap.get('id');
  if (id) {
    this.emailEntity.UpdatedBy = this.globals.authData.UserId;
    this.submitted = false;
  } else {
    this.emailEntity.CreatedBy = this.globals.authData.UserId;
    this.emailEntity.UpdatedBy = this.globals.authData.UserId;
    this.submitted = true;
  }
  if (EmailForm.valid && this.des_valid == false) {
    this.btn_disable = true;
    this.emailEntity.check = 0;
    this.EmailTemplateService.add(this.emailEntity)
      .then((data) => {
          this.btn_disable = false;
          this.submitted = false;
          this.emailEntity = {};
          EmailForm.form.markAsPristine();
          if (id) {

            swal({

              type: 'success',
              title: 'Updated!',
              text: 'Email Template has been updated successfully.',

              showConfirmButton: false,
              timer: 4000
            })
          } else {

            swal({

              type: 'success',
              title: 'Added!',
              text: 'Email Template has been added successfully.',

              showConfirmButton: false,
              timer: 4000
            })
          }
          this.router.navigate(['/email-templatelist']);
        
      },
        (error) => {
          this.btn_disable = false;
          this.submitted = false;
          this.globals.isLoading = false;
          this.router.navigate(['/dashboard']);
          //this.router.navigate(['/admin/pagenotfound']);
        });
  }
}

clearForm(EmailForm) {
  this.emailEntity = {};
  this.emailEntity.EmailId = 0;
  this.emailEntity.IsActive = '1';
  this.submitted = false;
  this.des_valid = false;
  this.emailEntity.TokenId = '';
  this.emailEntity.To = '';
  this.emailEntity.Cc = '';
  this.emailEntity.Bcc = '';
  //CKEDITOR.instances.EmailBody.setData('');
  EmailForm.form.markAsPristine();
}
}
