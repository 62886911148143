import { Component, OnInit } from '@angular/core';

import { Globals } from '.././globals';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { debug } from 'util';

declare var $, swal: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginEntity;
  submitted;
  btn_disable;
  invalid;
  constructor(private router: Router, private route: ActivatedRoute, private AuthService: AuthService, public globals: Globals) { }

  ngOnInit() {
    
    this.globals.isLoading = false;
    $(document).ready(function () {
      const body = document.querySelector('body');
      body.style.setProperty('--screen-height', $(window).height() - 100 + "px");
      body.style.setProperty('--login-screen-height', $(window).height() + "px");
    });
    this.loginEntity = {};
  }
  login(loginForm) {
    debugger
    this.submitted = true;
    if (loginForm.valid) {
      this.btn_disable = true;
      this.globals.isLoading = true;
      this.loginEntity.RoleId = 1;
      this.AuthService.login(this.loginEntity)
        .then((data) => {
          if(data=='error')
          {
            swal({
              type: 'warning',
              title: 'Oops...',
              text: 'Either username or password is incorrect!',
            })
            this.globals.isLoading = false;
          }else{
          swal({
            //position: 'top-end',
            type: 'success',
            title: 'You are logged in successfully',
            showConfirmButton: false,
            timer: 4000
          })
          this.globals.isLoading = false;

          window.location.href = '/candidate-list';
        }
        },
          (error) => {
      
            swal({
              type: 'warning',
              title: 'Oops...',
              text: 'Either username or password is incorrect!',
            })
            this.globals.isLoading = false;
            this.btn_disable = false;
            this.submitted = false;

          });
    }
  }

}

