import { Component, OnInit } from '@angular/core';

import { Globals } from '.././globals';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute, private AuthService: AuthService, public globals: Globals) { }


  ngOnInit() {
  }
  logout() {
    var panel = { 'Userid': this.globals.authData.UserId, 'paneltype': 0 };
    this.AuthService.logout(panel)
      .then((data) => {
        this.globals.isLoading = true;
        window.location.href = '/login';

      },
        (error) => {
          //alert('error');
          this.globals.isLoading = false;

        });

  }
}
