import { Injectable } from '@angular/core';
import { Globals } from '.././globals';
import { HttpClient } from "@angular/common/http";
import { Router } from '@angular/router';
declare var $, swal: any;

@Injectable({
  providedIn: 'root'
})
export class ActiveDeleteService {

  constructor(private http: HttpClient, private globals: Globals, private router: Router) { }
  isActiveChange(changeEntity) {
    
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.globals.baseAPIUrl + 'ActiveDelete/isActiveChange', changeEntity)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
            this.globals.isLoading = false;
              swal({
                //position: 'top-end',
                type: 'error',
                title: 'Oops...',
                text: "Something went wrong!"
              })
          }
        );
    });
    return promise;
  }
  deleteItem(del) {
    
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.globals.baseAPIUrl + 'ActiveDelete/deleteItem', del)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
            this.globals.isLoading = false;
              swal({
                //position: 'top-end',
                type: 'error',
                title: 'Oops...',
                text: "Something went wrong!"
              })
            
          }
        );
    });
    return promise;
  }
}
