import { Component, OnInit } from '@angular/core';
import { Globals } from '.././globals';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { EmailTemplateService } from '../services/email-template.service';
import { ActiveDeleteService } from '../services/active-delete.service';
declare var $, swal: any, PerfectScrollbar;

@Component({
  selector: 'app-email-template-list',
  templateUrl: './email-template-list.component.html',
  styleUrls: ['./email-template-list.component.css']
})
export class EmailTemplateListComponent implements OnInit {
  deleteEntity;
  EmailList;
  constructor(public globals: Globals, private router: Router, private route: ActivatedRoute,
    private EmailTemplateService: EmailTemplateService,private ActiveDeleteService: ActiveDeleteService) { }

  ngOnInit() {
    debugger
    this.globals.isLoading = false;
    this.EmailList = [];
    $(document).ready(function () {
      const body = document.querySelector('body');
      body.style.setProperty('--screen-height', $(window).height() - 100 + "px");
    });
    new PerfectScrollbar('.content_height');
    this.EmailTemplateService.getAll()
			.then((data) => {
				let todaysdate = this.globals.todaysdate;
				setTimeout(function () {
					var table = $('#dataTables-example').DataTable({
						//  scrollY: '55vh',
						responsive: {
							details: {
								display: $.fn.dataTable.Responsive.display.childRowImmediate,
								type: ''
							}
						},
						scrollCollapse: true,
						"oLanguage": {
							"sLengthMenu": "_MENU_ Email Templates per page",
							"sInfo": "Showing _START_ to _END_ of _TOTAL_ Email Templates",
							"sInfoFiltered": "(filtered from _MAX_ total Email Templates)",
							"sInfoEmpty": "Showing 0 to 0 of 0 Email Templates"
						},
						dom: 'lBfrtip',
						buttons: [
              {
								extend: 'excel',
								title: 'Evaluation – All Email Templates – ' + todaysdate,
								filename: 'Evaluation–AllEmailTemplates–' + todaysdate,

								customize: function (xlsx) {
									var source = xlsx.xl['workbook.xml'].getElementsByTagName('sheet')[0];
									source.setAttribute('name', 'Evaluation-AllEmailTemplates');
								},
								exportOptions: {
									columns: [0, 1, 2, 3, 4, 5, 6]
								}
							},
							{
								extend: 'print',
								title: 'Evaluation – All Email Templates – ' + todaysdate,
								exportOptions: {
									columns: [0, 1, 2, 3, 4, 5, 6]
								}
							},
						]
          });
          
          $(".buttons-print").append("<i class='fa fa-print'></i>");
          $('.buttons-print').attr('title', 'Print');
          $('.buttons-print').attr('data-toggle', 'tooltip');
          $('.buttons-print').attr('data-placement', 'top');
          $(".buttons-excel").append("<i class='fa fa-file-excel-o'></i>");
          $('.buttons-excel').attr('title', 'Export to Excel');
          $('.buttons-excel').attr('data-toggle', 'tooltip');
          $('.buttons-excel').attr('data-placement', 'top');
        }, 100);
				if (data) {
					this.EmailList = data;
				}
				this.globals.isLoading = false;
			},
				(error) => {
					this.globals.isLoading = false;
					this.router.navigate(['/pagenotfound']);
				});
  }
  deleteEmail(Email) {
		swal({
			title: 'Delete Email Template',
			text: "Are you sure you want to delete this Email Template?",
			icon: "warning",
      type: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes',
			cancelButtonText: "No"
		})
			.then((result) => {
				if (result.value) {
          Email.UserId = this.globals.authData.UserId;
          Email.Id = Email.EmailId;
          Email.TableName = 'tblemailtemplate';
          Email.FieldName = 'EmailId';
          Email.Module = 'Email Template';
          Email.ActivityText = 'Delete Email Template - ' + Email.Token + ' (Id - ' + Email.EmailId + ')';
          this.globals.isLoading = true;
					this.ActiveDeleteService.deleteItem(Email)
						.then((data) => {
							let index = this.EmailList.indexOf(Email);
							if (index != -1) {
								this.EmailList.splice(index, 1);
							}
              this.globals.isLoading = false;
							swal({
								type: 'success',
								title: 'Deleted!',
								text: 'Email Template has been deleted successfully.',
								showConfirmButton: false,
								timer: 4000
							})
						},
            (error) => {
              this.globals.isLoading = false;
              if (error.text) {
                swal({
                  //position: 'top-end',
                  type: 'error',
                  title: 'Oops...',
                  text: "Something went wrong!"
                })
              }
            });
				}
			})
	}


	isActiveChange(changeEntity, i) {
    if (this.EmailList[i].IsActive == 1) {
      changeEntity.IsActive = 0;
    } else {
      changeEntity.IsActive = 1;
    }
		this.globals.isLoading = true;
		changeEntity.UpdatedBy = this.globals.authData.UserId;
		changeEntity.Id = changeEntity.EmailId;
		changeEntity.TableName = 'tblemailtemplate';
		changeEntity.FieldName = 'EmailId';
    changeEntity.Module = 'Email Template';
    if(changeEntity.IsActive == 1){
      changeEntity.ActivityText = "Email Template Activated - " + changeEntity.Token;
    }
    else{
      changeEntity.ActivityText = "Email Template Deactivated - " + changeEntity.Token;
    }
		this.ActiveDeleteService.isActiveChange(changeEntity)
			.then((data) => {
				this.globals.isLoading = false;
        if(changeEntity.IsActive == 0){
          swal({
            //position: 'top-end',
            type: 'success',
            title: 'Email Template deactivated successfully',
            showConfirmButton: false,
            timer: 4000
          })
        }
        else{
          swal({
            //position: 'top-end',
            type: 'success',
            title: 'Email Template activated successfully',
            showConfirmButton: false,
            timer: 4000
          })
        }
			},
      (error) => {
        this.globals.isLoading = false;
        if (this.EmailList[i].IsActive == 1) {
          $('#active'+i).prop("checked", true);
        }
        else{
          $('#active'+i).prop("checked", false);
        } 
      });
	}

}
