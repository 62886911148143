import { Component, OnInit,ElementRef,ViewChild } from '@angular/core';
import { FormControl, NgModel } from '@angular/forms';
import { Globals } from '.././globals';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router'; 
import { CandidateFormService } from '../services/candidate-form.service';
import { TagInputComponent as SourceTagInput } from 'ngx-chips';
import { throwError, of, empty } from 'rxjs';
declare var $, PerfectScrollbar, paypal, html2pdf,swal, getAccordion: any;
@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.css']
})
export class ClientListComponent implements OnInit {
  @ViewChild('tagInput')
  tagInput: SourceTagInput;
 
  constructor(public globals: Globals, private router: Router, private elem: ElementRef,private route: ActivatedRoute, private CandidateFormService: CandidateFormService) { }
  clientList;
  ClientEntity;
  submitted;
  TodaysDate;
  submitted2;
  submitted3;
  submitted4;
  salary_valid;
  salary1_valid;
  PayRate_valid;
  To_valid;
  btn_disable;
  ClientEmailEntity;
  EmailEntity;
  submitted1;
  addClientEntity;
  editClientEntity;
  Candidatename;
  ngOnInit() {
    this.ClientEntity={};
    this.ClientEmailEntity={};
    this.addClientEntity={}
    this.Candidatename={};
    this.editClientEntity={};
    this.EmailEntity={};
    this.addClientEntity.ClientId=0;
    this.globals.isLoading=true;
    new PerfectScrollbar('.content_height');
    $('input[type="radio"]').click(function () {
      var inputValue = $(this).attr("value");
      var targetBox = $("." + inputValue);
      $(".box").not(targetBox).css("display", "none");
      $(targetBox).css("display", "inline-block");
    });
    $('.salary_block').hide();
$('#full_time input').click(function () {
  $('.position_type_block .col-md-4').removeClass("width_long");
  $('.position_type_block .col-md-4').addClass("width_small");
  $('#full_time').addClass("width_long");
  $('#full_time').removeClass("width_small");
  $('#contract').removeClass("width_long");
  $('#part_time').removeClass("width_long");
  $('#contract').addClass("width_small");
  $('#part_time').addClass("width_small");
  $('.salary_block').hide();
  $("#full_time .salary_block").fadeIn(700);
});
$('#part_time input').click(function () {
  $('.position_type_block .col-md-4').removeClass("width_long");
  $('.position_type_block .col-md-4').addClass("width_small");
  $('#part_time').addClass("width_long");
  $('#part_time').removeClass("width_small");
  $('#contract').removeClass("width_long");
  $('#full_time').removeClass("width_long");
  $('#contract').addClass("width_small");
  $('#full_time').addClass("width_small");
  $('.salary_block').hide();
  $('#part_time .salary_block').fadeIn(700);
});
$('#contract input').click(function () {
  $('.position_type_block .col-md-4').removeClass("width_long");
  $('.position_type_block .col-md-4').addClass("width_small");
  $('#contract').addClass("width_long");
  $('#contract').removeClass("width_small");
  $('#part_time').removeClass("width_long");
  $('#full_time').removeClass("width_long");
  $('#part_time').addClass("width_small");
  $('#full_time').addClass("width_small");
  $('.salary_block').hide();
  $('#contract .salary_block').fadeIn(700);
});
$('#addclientpopup .email_block').hide();
$('#addclientpopup .modal-footer').hide();
$('#addclientpopup #email_save').click(function () {
  $('#addclientpopup .email_block').fadeIn(700);
  $('#addclientpopup .modal-footer').fadeIn(700);
  $('#addclientpopup .button_hide').hide();
});
$('#addclientpopup .close_email').click(function () {
  $('#addclientpopup .email_block').hide();
  $('#addclientpopup .modal-footer').hide();
  $('#addclientpopup .button_hide').show();
});
$('#editclientpopup .email_block').hide();
$('#editclientpopup .modal-footer').hide();
$('#editclientpopup #email_save').click(function () {
  $('#editclientpopup .email_block').fadeIn(700);
  $('#editclientpopup .modal-footer').fadeIn(700);
  $('#editclientpopup .button_hide').hide();
});
$('#editclientpopup .close_email').click(function () {
  $('#editclientpopup .email_block').hide();
  $('#editclientpopup .modal-footer').hide();
  $('#editclientpopup .button_hide').show();
});

     let id = this.route.snapshot.paramMap.get('id');
    if (id) {
   
    this.CandidateFormService.getAllClient(id)
       .then((data) => 
       { debugger
        this.clientList = data['client'];	
       this.Candidatename = data['Candidatename'];	
        this.globals.isLoading=false;
        setTimeout(function()
          {
            var table = $('#history_tables').DataTable({
              //  scrollY: '55vh',
              responsive: {
                details: {
                  display: $.fn.dataTable.Responsive.display.childRowImmediate,
                  type: ''
                }
              },
              scrollCollapse: true,
              "oLanguage": {
                "sLengthMenu": "_MENU_ Clients per page",
                "sInfo": "Showing _START_ to _END_ of _TOTAL_ Clients",
                "sInfoFiltered": "(filtered from _MAX_ total Clients)",
                "sInfoEmpty": "Showing 0 to 0 of 0 Clients"
              },
              dom: 'lBfrtip',
              buttons: [
                {
                  extend: 'excel',
                  title: 'Recruitment – All Clients – ',// + todaysdate,
                  filename: 'Recruitment–AllClients–', // + todaysdate,
                  exportOptions: {
                    columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
                  },
                  customize: function (xlsx) {
                    var source = xlsx.xl['workbook.xml'].getElementsByTagName('sheet')[0];
                    source.setAttribute('name', 'Recruitment-AllCandidates');
                  },
                },
                {
                  extend: 'print',
                  title: 'Recruitment – All Candidates – ' ,//+ todaysdate
                  exportOptions: {
                    columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
                  }
                },
              ]
            });
            
          $(".buttons-print").append("<i class='fa fa-print'></i>");
          $('.buttons-print').attr('title', 'Print');
          $('.buttons-print').attr('data-toggle', 'tooltip');
          $('.buttons-print').attr('data-placement', 'top');
          $(".buttons-excel").append("<i class='fa fa-file-excel-o'></i>");
          $('.buttons-excel').attr('title', 'Export to Excel');
          $('.buttons-excel').attr('data-toggle', 'tooltip');
          $('.buttons-excel').attr('data-placement', 'top');
        },100);
    
       
        
  }, 
  (error) => 
  {
this.globals.isLoading = false;
    this.router.navigate(['/pagenotfound']);
  });
}else
{
  this.globals.isLoading=false;
}
}
public validators = [this.must_be_email.bind(this)];
public errorMessages = {
  'must_be_email': 'Please be sure to use a valid email format'
};
public onAddedFunc = this.beforeAdd.bind(this);
private addFirstAttemptFailed = false;
private must_be_email(control: FormControl) {
  if (this.addFirstAttemptFailed && !this.validateEmail(control.value)) {
    return { "must_be_email": true };
  }
  return null;
}
private beforeAdd(tag: string) {

  if (!this.validateEmail(tag)) {
    if (!this.addFirstAttemptFailed) {
      this.addFirstAttemptFailed = true;
      this.tagInput.setInputValue(tag);
    }

    return throwError(this.errorMessages['must_be_email']);
    //return of('').pipe(tap(() => setTimeout(() => this.tagInput.setInputValue(tag))));
    
  }
  this.addFirstAttemptFailed = false;
  return of(tag);
}
private validateEmail(text: string) {
  var EMAIL_REGEXP = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/i;
  return (text && EMAIL_REGEXP.test(text));
}
   getPDF(){
    var element = document.getElementById('pdfrecruiter');
    var opt = {
    margin:       1,
    filename:     'myfile.pdf',
    image:        { type: 'jpeg', quality: 1 },
    html2canvas:  {scale: 2},
    jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
  };
  
    html2pdf().from(element).set(opt).toPdf().get('pdf').then(function (pdf) {
      
      var totalPages = pdf.internal.getNumberOfPages();
  
        for (var i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
       
        pdf.text(50, 50, 'new text here');
        }
    }).save();
  }
  newadd()
  {
    
    $('#addclientpopup').modal('show');
    this.submitted2 = false;
    this.submitted1 = false;
    this.salary_valid=false;
    this.To_valid=false;
    this.addClientEntity={};
    this.addClientEntity.ClientId=0;
     let id = this.route.snapshot.paramMap.get('id');
      this.addClientEntity.CandidateId=id;
      this.addClientEntity.PositionType="1";
      $('#full_time').addClass("width_long");
      $('#full_time').removeClass("width_small");

    
  }
  addClientNew(ClientForm)
  {debugger
 this.globals.isLoading = true;
    var count=0;
    if(this.addClientEntity.PositionType==1)
    {
       if(this.addClientEntity.FSalary!='' && this.addClientEntity.FSalary!=undefined && this.addClientEntity.FSalary!=null)
       {
         this.addClientEntity.Salary=this.addClientEntity.FSalary;
         this.salary_valid=false;
       }
       else
       {
         this.salary_valid=true;
         count=1;
       }
    }else
    {
         this.salary_valid=false;
    }
    if(this.addClientEntity.PositionType==2)
    {
       if(this.addClientEntity.PSalary!='' && this.addClientEntity.PSalary!=undefined && this.addClientEntity.PSalary!=null)
       {
         this.addClientEntity.Salary=this.addClientEntity.PSalary;
         this.salary1_valid=false;
       }
       else
       {
         this.salary1_valid=true;
         count=1;
       }
     }else
    {
     this.salary1_valid=false;
    }
   if(this.addClientEntity.PositionType==3)
   {
       if(this.addClientEntity.PayRate!='' && this.addClientEntity.PayRate!=undefined && this.addClientEntity.PayRate!=null)
       {
         this.addClientEntity.Salary=this.addClientEntity.PayRate;
         this.PayRate_valid=false;
       }
       else
       {
         this.PayRate_valid=true;
         count=1;
       }
   }else
   {
     this.PayRate_valid=false;
   }
    this.submitted2=true;
    this.addClientEntity.ClientId=0;
    this.addClientEntity.UserId=this.globals.authData.UserId;
  if (ClientForm.valid && count==0) 
  {
      //addclientemail
  
       this.CandidateFormService.addclientsingle(this.addClientEntity)
      .then((data) => {
    this.addClientEntity.ClientId=data;
    this.submitted2=false;
    ClientForm.form.markAsPristine();
            swal({
              type: 'success',
              title: 'Success...!',
              text: 'client added successfully.',
              showConfirmButton: false,
              timer: 3000
            })
				this.clientList.push(this.addClientEntity);
        $('#addclientpopup').modal('hide');
        this.globals.isLoading = false;
      },
        (error) => {
          this.btn_disable = true;
          this.submitted2 = false;
          this.globals.isLoading = false;
          ClientForm.form.markAsPristine();
        });
 
    }else
    {
      this.globals.isLoading = false;
    }
}
  ClientNew(ClientForm)
  {debugger
    this.submitted2=true;
    this.globals.isLoading = false;
    this.globals.isLoading = true;
    var count=0;
    if(this.addClientEntity.PositionType==1)
   {
       if(this.addClientEntity.FSalary!='' && this.addClientEntity.FSalary!=undefined && this.addClientEntity.FSalary!=null)
       {
         this.addClientEntity.Salary=this.addClientEntity.FSalary;
         this.salary_valid=false;
       }
       else
       {
         this.salary_valid=true;
         count=1;
       }
   }else
   {
   this.salary_valid=false;
   }
   if(this.addClientEntity.PositionType==2)
   {
       if(this.addClientEntity.PSalary!='' && this.addClientEntity.PSalary!=undefined && this.addClientEntity.PSalary!=null)
       {
         this.addClientEntity.Salary=this.addClientEntity.PSalary;
         this.salary1_valid=false;
       }
       else
       {
         this.salary1_valid=true;
         count=1;
       }
   }else
   {
     this.salary1_valid=false;
   }
   if(this.addClientEntity.PositionType==3)
   {
       if(this.addClientEntity.PayRate!='' && this.addClientEntity.PayRate!=undefined && this.addClientEntity.PayRate!=null)
       {
         this.addClientEntity.Salary=this.addClientEntity.PayRate;
         this.PayRate_valid=false;
       }
       else
       {
         this.PayRate_valid=true;
         count=1;
       }
   }else
   {
     this.PayRate_valid=false;
   }

    if (ClientForm.valid && count==0) {
 
   
        $('#addclientpopup .email_block').fadeIn(700);
        $('#addclientpopup .modal-footer').fadeIn(700);
        $('#addclientpopup .button_hide').hide();
       this.btn_disable = true;
       this.submitted2 = false;
       this.globals.isLoading = false;
       ClientForm.form.markAsPristine();
     }else
     {
      this.globals.isLoading = false;
     }
  }
  addClientemail(ClientEmailForm)
  {debugger
    this.globals.isLoading = true;
    this.submitted1=true;
    var count=0;
  if(this.addClientEntity.To!='' && this.addClientEntity.To!=undefined && this.addClientEntity.To!=null)
    {
      this.To_valid=false;
    }
    else
    {
      this.To_valid=true;
      count=1;
    }
    if(this.addClientEntity.AttachResume==true)
    {
     this.addClientEntity.AttachResume=true;
    }else
    {
     this.addClientEntity.AttachResume=false;
    }
   if(this.addClientEntity.activedetails==true)
    {
     this.addClientEntity.activedetails=true;
    }else
    {
     this.addClientEntity.activedetails=false;
    }
   
    if (ClientEmailForm.valid && count==0) {
      this.addClientEntity.To = this.addClientEntity.To.join();
      this.addClientEntity.UserId=this.globals.authData.UserId;
      this.CandidateFormService.addclient(this.addClientEntity)
      .then((data) => {

        swal({
          type: 'success',
          title: 'Success...!',
          text: 'sent email successfully',
          showConfirmButton: false,
          timer: 3000
        })
        var date = Date.now();
         this.addClientEntity.sentfrom=this.addClientEntity.From;
         this.addClientEntity.sendTo=this.addClientEntity.To;
         this.addClientEntity.UpdatedOn=this.addClientEntity.From;
         this.addClientEntity.UpdatedOn=date;
        
           this.clientList.push(this.addClientEntity);
           this.btn_disable = true;
           this.submitted1 = false;
           $('#addclientpopup').modal('hide');
           ClientEmailForm.form.markAsPristine();
           this.globals.isLoading = false;
      },
        (error) => {
          this.btn_disable = true;
          this.submitted1 = false;
          this.globals.isLoading = false;
          ClientEmailForm.form.markAsPristine();
        });
     }else
     {
      this.globals.isLoading = false;
     }
  }
  editclient(client,i)
  {debugger
    this.globals.isLoading = true;
    $('#editclientpopup').modal('show');
    this.submitted=false;
    this.ClientEntity=client;
    setTimeout(function()
    {   

    $('#editclientpopup .email_block').fadeIn(700);
    $('#editclientpopup .modal-footer').fadeIn(700);
    $('#editclientpopup .button_hide').hide();
    $('.salary_block').hide();
   
  
    $('#full_time1 input').click(function () {
      $('.position_type_block .col-md-4').removeClass("width_long");
      $('.position_type_block .col-md-4').addClass("width_small");
      $('#full_time1').addClass("width_long");
      $('#full_time1').removeClass("width_small");
      $('#contract1').removeClass("width_long");
      $('#part_time1').removeClass("width_long");
      $('#contract1').addClass("width_small");
      $('#part_time1').addClass("width_small");
      $('.salary_block').hide();
      $("#full_time1 .salary_block").fadeIn(700);
    });
    $('#part_time1 input').click(function () {
      $('.position_type_block .col-md-4').removeClass("width_long");
      $('.position_type_block .col-md-4').addClass("width_small");
      $('#part_time1').addClass("width_long");
      $('#part_time1').removeClass("width_small");
      $('#contract1').removeClass("width_long");
      $('#full_time1').removeClass("width_long");
      $('#contract1').addClass("width_small");
      $('#full_time1').addClass("width_small");
      $('.salary_block').hide();
      $('#part_time1 .salary_block').fadeIn(700);
    });
    $('#contract1 input').click(function () {
      $('.position_type_block .col-md-4').removeClass("width_long");
      $('.position_type_block .col-md-4').addClass("width_small");
      $('#contract1').addClass("width_long");
      $('#contract1').removeClass("width_small");
      $('#part_time1').removeClass("width_long");
      $('#full_time1').removeClass("width_long");
      $('#part_time1').addClass("width_small");
      $('#full_time1').addClass("width_small");
      $('.salary_block').hide();
      $('#contract1 .salary_block').fadeIn(700);
    });
   // $(".bootstrap-tagsinput input[type='text']").val('');
    $("#fromemail1").val(client.sendTo);
    $('[data-role="tags-input"]').tagsInput();
    
  //  $(".bootstrap-tagsinput input[type='text']").val();
  },100);
    
  
    if(this.ClientEntity.sendTo!=null){
      this.ClientEntity.To1 = this.ClientEntity.sendTo.split(","); 	//convert comma seperated string to array
    }
    this.ClientEntity.From1=client.sentfrom;
    if(client.AttachResume==1)
    {
      this.ClientEntity.AttachResume1=true;
    }else
    {
      this.ClientEntity.AttachResume1=false;
    }
    if(client.PositionType==1)
    {
      this.ClientEntity.FSalary1=client.Salary;
      setTimeout(function()
      {     
      $('#full_time1').addClass("width_long");
      $('#full_time1').removeClass("width_small");
      $('#contract1').removeClass("width_long");
      $('#part_time1').removeClass("width_long");
      $('#part_time1').addClass("width_small");
      $('#contract1').addClass("width_small");
      $('.salary_block').hide();
      $("#full_time1 .salary_block").fadeIn(200);
    },100);
    }else if(client.PositionType==2)
    {
      this.ClientEntity.PSalary1=client.Salary;
      setTimeout(function()
      {  
      $('#part_time1').addClass("width_long");
      $('#part_time1').removeClass("width_small");
      $('#contract1').removeClass("width_long");
      $('#full_time1').removeClass("width_long");
      $('#full_time1').addClass("width_small");
      $('#contract1').addClass("width_small");
      $('.salary_block').hide();
      $("#part_time1 .salary_block").fadeIn(200);
    },100);
    }else
    {
      this.ClientEntity.PayRate1=client.Salary;
      setTimeout(function()
      {  
      $('#contract1').addClass("width_long");
      $('#contract1').removeClass("width_small");
      $('#part_time1').removeClass("width_long");
      $('#full_time1').removeClass("width_long");
      $('#full_time1').addClass("width_small");
      $('#part_time1').addClass("width_small");
      $('.salary_block').hide();
      $("#contract1 .salary_block").fadeIn(200);
      },100);
    }
    this.ClientEntity.PositionType1=client.PositionType;
    this.ClientEntity.ClientName1=client.ClientName;
    this.ClientEntity.EmailAddress1=client.EmailAddress;
    this.ClientEntity.PositionAppliedFor1=client.PositionAppliedFor;
    this.ClientEntity.PhoneNumber1=client.PhoneNumber;

    
    this.ClientEntity.ExternalComment1=client.ExternalComment;
    this.ClientEntity.Subject1=client.Subject;
    this.ClientEntity.i=i;
    this.globals.isLoading = false;
    
  }
editClientNew(EditClientForm)
{debugger

  this.globals.isLoading = true;
  this.submitted1=false;
  var count=0;
    if(this.ClientEntity.PositionType1==1)
   {
       if(this.ClientEntity.FSalary1!='' && this.ClientEntity.FSalary1!=undefined && this.ClientEntity.FSalary1!=null)
       {
         this.ClientEntity.Salary=this.ClientEntity.FSalary1;
         this.salary_valid=false;
       }
       else
       {
         this.salary_valid=true;
         count=1;
       }
   }else
   {
   this.salary_valid=false;
   }
   if(this.ClientEntity.PositionType1==2)
   {
       if(this.ClientEntity.PSalary1!='' && this.ClientEntity.PSalary1!=undefined && this.ClientEntity.PSalary1!=null)
       {
         this.ClientEntity.Salary=this.ClientEntity.PSalary1;
         this.salary1_valid=false;
       }
       else
       {
         this.salary1_valid=true;
         count=1;
       }
   }else
   {
     this.salary1_valid=false;
   }
   if(this.ClientEntity.PositionType1==3)
   {
       if(this.ClientEntity.PayRate1!='' && this.ClientEntity.PayRate1!=undefined && this.ClientEntity.PayRate1!=null)
       {
         this.ClientEntity.Salary=this.ClientEntity.PayRate1;
         this.PayRate_valid=false;
       }
       else
       {
         this.PayRate_valid=true;
         count=1;
       }
   }else
   {
     this.PayRate_valid=false;
   }
  
   this.ClientEntity.UserId=this.globals.authData.UserId;

   var date = Date.now();
 
  //  var time=new  time();
  //  alert(time);
  //   var datetime= date + time;
  //   alert(datetime);
  if (EditClientForm.valid && count==0) {
    this.CandidateFormService.addclient(this.ClientEntity)
    .then((data) => {
    this.ClientEntity.ClientId=data;
    $('#editclientpopup').modal('hide');
    swal({
      type: 'success',
      title: 'Updated!',
      text: 'client updated successfully',
      showConfirmButton: false,
      timer: 3000
    })
    this.globals.isLoading = false;
         this.clientList[this.ClientEntity.i].Salary=this.ClientEntity.Salary;
         this.clientList[this.ClientEntity.i].ClientName=this.ClientEntity.ClientName1;
         this.clientList[this.ClientEntity.i].EmailAddress=this.ClientEntity.EmailAddress1;
         this.clientList[this.ClientEntity.i].PhoneNumber=this.ClientEntity.PhoneNumber1;
         this.clientList[this.ClientEntity.i].PositionAppliedFor=this.ClientEntity.PositionAppliedFor1;
         this.clientList[this.ClientEntity.i].ExternalComment=this.ClientEntity.ExternalComment1;
         this.clientList[this.ClientEntity.i].PositionType=this.ClientEntity.PositionType1;
         this.clientList[this.ClientEntity.i].UpdatedOn=date;
    
    },
      (error) => {
  this.btn_disable = true;
     this.submitted1 = false;
     this.globals.isLoading = false;
     EditClientForm.form.markAsPristine();
      });
   
   }else
  {
    this.globals.isLoading = false;
  }
}
sendemailclient(client,i)
{    
  this.submitted3=false;
  this.To_valid=false;
  this.EmailEntity={};
  $('#emailpopup').modal('show');
  this.EmailEntity.i=i;
  this.EmailEntity.ClientId=client.ClientId;
  this.EmailEntity.ClientName=client.ClientName;
}
addemail(EmailForm1)
{debugger

  var count=0;
  this.globals.isLoading = true;
    if(this.EmailEntity.To2!='' && this.EmailEntity.To2!=undefined && this.EmailEntity.To2!=null)
    {
      this.To_valid=false;
    }
    else
    {
      this.To_valid=true;
      count=1;
    } 
    if(this.EmailEntity.activedetails2==true)
    { this.EmailEntity.activedetails2=true;
    }else
    {this.EmailEntity.activedetails2=false;
    }
    if(this.EmailEntity.AttachResume2==true)
    { this.EmailEntity.AttachResume2=true;
    }else
    {this.EmailEntity.AttachResume2=false;
    }
    let id = this.route.snapshot.paramMap.get('id');
    this.EmailEntity.CandidateId=id;
    var date = Date.now();
     this.submitted3=true;
    if (EmailForm1.valid && count==0) {
      this.EmailEntity.To2 = this.EmailEntity.To2.join();
      this.EmailEntity.UserId=this.globals.authData.UserId;
      this.CandidateFormService.addclientemail(this.EmailEntity)
      .then((data) => {
      $('#emailpopup').modal('hide');
      swal({
        type: 'success',
        title: 'Success...!',
        text: 'sent email successfully',
        showConfirmButton: false,
        timer: 3000
      })
           this.globals.isLoading = false;
           this.clientList[this.EmailEntity.i].sentfrom=this.EmailEntity.From2;
           this.clientList[this.EmailEntity.i].sendTo=this.EmailEntity.To2;
           this.clientList[this.EmailEntity.i].Subject=this.EmailEntity.Subject2;
           this.clientList[this.EmailEntity.i].UpdatedOn=date;
      },
        (error) => {
          this.btn_disable = true;
          this.submitted3 = false;
          this.globals.isLoading = false;
          EmailForm1.form.markAsPristine();
        });
      
     }else
     {this.globals.isLoading = false;}
}
Resendemailclient(client,i)
{debugger
  
 
  $('#emailpopup1').modal('show');
  //this.editClientEntity=client;
  this.editClientEntity.i=i;
  this.editClientEntity.ClientId=client.ClientId;
  this.editClientEntity.ExternalComment1=client.ExternalComment;
  this.editClientEntity.Subject1=client.Subject;

  this.editClientEntity.From1=client.sentfrom;
  if(client.AttachResume==1)
  {
    this.editClientEntity.AttachResume1=true;
  }else
  {
    this.editClientEntity.AttachResume1=false;
  }
  if(client.activedetails==1)
  {
    this.editClientEntity.activedetails1=true;
  }else
  {
    this.editClientEntity.activedetails1=false;
  }
  if(client.sendTo!=null){
    this.editClientEntity.To1 = client.sendTo.split(","); 	//convert comma seperated string to array
  }

  // swal({
  //   title: 'Re-send an Email',
  //   text: "Are you sure you want to re-send email?",
  //   type: 'warning',
  //   showCancelButton: true,
  //   confirmButtonColor: '#3085d6',
  //   cancelButtonColor: '#d33',
  //   confirmButtonText: 'Yes'
  // })
  //   .then((result) => {
  //     if (result.value) {
  //       debugger
  //       this.globals.isLoading = true;
  //       if(client.activedetails2==true){ client.activedetails2=true;}else{client.activedetails2=false;}
  //       if(client.AttachResume2==true){ client.AttachResume2=true;}else{client.AttachResume2=false;}
  //    client.To2=client.sendTo;
  //    client.From2=client.sentfrom;
  //    client.Subject2=client.Subject;
  //    client.AttachResume2=client.AttachResume;
  //    client.UserId=this.globals.authData.UserId;
  //    if(client.AttachResume2==1)
  //    {
  //       client.AttachResume2=true;
  //    }else
  //    {
  //       client.AttachResume2=false;
  //    }
  //       this.CandidateFormService.addclientemail(client)
  //       .then((data) => {
  //         swal({
  //           type: 'success',
  //           title: 'Success...!',
  //           text: 'sent email successfully',
  //           showConfirmButton: false,
  //           timer: 3000
  //         })
  //           this.globals.isLoading = false;
  //         },
  //           (error) => {
  //             swal({

  //               type: 'error',
  //               title:'Oops...',
  //               text: "You can't delete this record because of their dependency!",
  //               showConfirmButton: false,
  //               timer: 1500
  //             })
  //           });
  //     }else
  //     {
  //       this.globals.isLoading = false;
  //     }
  //   })
}
editemail(EmailForm)
{  
  this.globals.isLoading = true;
  this.submitted4 = false;
  var count=0;

    if(this.editClientEntity.To1!='' && this.editClientEntity.To1!=undefined && this.editClientEntity.To1!=null)
    {
      this.To_valid=false;
    }
    else
    {
      this.To_valid=true;
      count=1;
    } 
    if(this.editClientEntity.activedetails1==true)
    { this.editClientEntity.activedetails1=true;
    }else
    {this.editClientEntity.activedetails1=false;
    }
    if(this.editClientEntity.AttachResume1==true)
    { this.editClientEntity.AttachResume1=true;
    }else
    {this.editClientEntity.AttachResume1=false;
    }
    let id = this.route.snapshot.paramMap.get('id');
    this.editClientEntity.CandidateId=id;
    var date = Date.now();
    if (EmailForm.valid && count==0) {
      this.editClientEntity.activedetails2=this.editClientEntity.activedetails1;
      this.editClientEntity.AttachResume2=this.editClientEntity.AttachResume1;
      this.editClientEntity.From2=this.editClientEntity.From1;
      this.editClientEntity.Subject2=this.editClientEntity.Subject1;
      this.editClientEntity.To2 = this.editClientEntity.To1.join();
      this.editClientEntity.UserId=this.globals.authData.UserId;
      this.CandidateFormService.addclientemail(this.editClientEntity)
      .then((data) => {
      $('#emailpopup1').modal('hide');
      swal({
        type: 'success',
        title: 'Success...!',
        text: 'sent email successfully',
        showConfirmButton: false,
        timer: 3000
      })
           this.globals.isLoading = false;
           this.clientList[this.editClientEntity.i].sentfrom=this.editClientEntity.From2;
           this.clientList[this.editClientEntity.i].sendTo=this.editClientEntity.To2;
           this.clientList[this.editClientEntity.i].Subject=this.editClientEntity.Subject2;
           this.clientList[this.editClientEntity.i].UpdatedOn=date;
      },
        (error) => {
          this.btn_disable = true;
          this.submitted3 = false;
          this.globals.isLoading = false;
          EmailForm.form.markAsPristine();
        });
      
     }else
     {this.globals.isLoading = false;}
}

}
