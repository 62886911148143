import { Component, OnInit, ElementRef } from '@angular/core';
import { Globals } from '.././globals';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { CandidateFormService } from '../services/candidate-form.service';

declare var $, PerfectScrollbar, paypal, swal, getAccordion: any;
@Component({
  selector: 'app-candidate-form',
  templateUrl: './candidate-form.component.html',
  styleUrls: ['./candidate-form.component.css']
})
export class CandidateFormComponent implements OnInit {

  constructor(public globals: Globals, private router: Router, private elem: ElementRef, private route: ActivatedRoute, private CandidateFormService: CandidateFormService) { }
  CandidateEntity;
  submitted;
  submittedSo;
  submitted1;
  submitted2;
  submitted3;
  submitted4;
  submitted5;
  submitted6;
  submitted7;
  btn_disable;
  header;
  msgflag;
  message;
  salary_valid;
  salary1_valid;
  PayRate_valid;
  TechnicalSkillsEntity;
  SoftSkillsEntity;
  QualificationEntity;
  CertificationEntity;
  WorkExperienceEntity;
  startdate_valid;
  earneddate_valid;
  submitbutton;
  PdfValid;
  ngOnInit() {
    this.globals.isLoading = true;
 
    this.CandidateEntity = {};
    this.CandidateEntity.PositionType = "1";
    this.CandidateEntity.CandidateId = 0;
    this.TechnicalSkillsEntity = [];
    var item = { 'Skill': '', 'ExperienceDuration': '0.5' };
    this.TechnicalSkillsEntity.push(item);

    this.SoftSkillsEntity = [];
    var item1 = { 'SSkill': '', 'SExperienceDuration': '0.5' };
    this.SoftSkillsEntity.push(item1);

    this.QualificationEntity = [];
    var item2 = { 'Institute': '', 'Location': '', 'Degree': '', 'PassingYear': '' };
    this.QualificationEntity.push(item2);

    this.CertificationEntity = [];
    var item3 = { 'Institute': '', 'CertificateName': '', 'CertificateNumber': '', 'EarnedDate': '' };
    this.CertificationEntity.push(item3);

    this.WorkExperienceEntity = [];
    var item4 = { 'CompanyName': '', 'Location': '', 'JobTitle': '', 'ReasonForLeaving': '', 'StartDate': '', 'EndDate': '', 'CurrentlyWorking': true };
    this.WorkExperienceEntity.push(item4);



    let id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.header = 'Edit';
      this.submitbutton = 'Update';
      this.CandidateFormService.getById(id)
        .then((data) => {
          this.CandidateEntity = data['Candidate'];
          this.TechnicalSkillsEntity = data['TechnicalSkill'];
          this.SoftSkillsEntity = data['SoftSkill'];
          this.CertificationEntity = data['Certification'];
          this.QualificationEntity = data['Qualification'];
          this.WorkExperienceEntity = data['WorkExperience'];
          if(this.CandidateEntity.PositionType==1)
          {
            this.CandidateEntity.FSalary=this.CandidateEntity.Salary;
            setTimeout(function()
            {     
                $('#full_time').addClass("width_long");
                $('#full_time').removeClass("width_small");
                $('#contract').removeClass("width_long");
                $('#part_time').removeClass("width_long");
                $('#part_time').addClass("width_small");
                $('#contract').addClass("width_small");
                $('.salary_block').hide();
                $("#full_time .salary_block").fadeIn(700);
            },100);
          }else if(this.CandidateEntity.PositionType==2)
          {
            this.CandidateEntity.PSalary = this.CandidateEntity.Salary;
            setTimeout(function()
            {  
            
              $('#part_time').addClass("width_long");
              $('#part_time').removeClass("width_small");
              $('#contract').removeClass("width_long");
              $('#full_time').removeClass("width_long");
              $('#full_time').addClass("width_small");
              $('#contract').addClass("width_small");
              $('.salary_block').hide();
              $("#part_time .salary_block").fadeIn(200);
            },100);
          }else
          {
            this.CandidateEntity.PayRate = this.CandidateEntity.Salary;
            setTimeout(function()
            {  
              $('#contract').addClass("width_long");
              $('#contract').removeClass("width_small");
              $('#part_time').removeClass("width_long");
              $('#full_time').removeClass("width_long");
              $('#full_time').addClass("width_small");
              $('#part_time').addClass("width_small");
              $('.salary_block').hide();
              $("#contract .salary_block").fadeIn(200);
            },100);
          }
          for (var i = 0; i < this.WorkExperienceEntity.length; i++) {
            var dateAr =  this.WorkExperienceEntity[i].StartDate.split('-');
            var newDate = dateAr[1] + '/' + dateAr[2] + '/' + dateAr[0];
            this.WorkExperienceEntity[i].StartDate=newDate;
            if(this.WorkExperienceEntity[i].EndDate==null)
            {
              this.WorkExperienceEntity[i].EndDate=null;
            }else
            {
              var dateAr1 =  this.WorkExperienceEntity[i].EndDate.split('-');
              var EndDate = dateAr1[1] + '/' + dateAr1[2] + '/' + dateAr1[0];
              this.WorkExperienceEntity[i].EndDate=EndDate;
            }
           
            this.WorkExperienceEntity[i].EndDate=EndDate;
            $("#StartDate" + i).val(this.WorkExperienceEntity[i].StartDate);
            $("#EndDate" + i).val(this.WorkExperienceEntity[i].EndDate);
            if (this.WorkExperienceEntity[i].CurrentlyWorking == 1)
            {
              this.WorkExperienceEntity[i].CurrentlyWorking = true;
            }
            else
            {
              this.WorkExperienceEntity[i].CurrentlyWorking = false;
            }
          }
          for (var i = 0; i < this.CertificationEntity.length; i++) {
            if(this.CertificationEntity[i].EarnedDate!=null)
            {
              var dateAr2 =  this.CertificationEntity[i].EarnedDate.split('-');
              var Enrate = dateAr2[1] + '/' + dateAr2[2] + '/' + dateAr2[0];
              this.CertificationEntity[i].EarnedDate=Enrate;
              $("#EarnedDate" + i).val(this.CertificationEntity[i].EarnedDate);
            }else
            {
              $("#EarnedDate" + i).val(this.CertificationEntity[i].EarnedDate);
            }
          
          }
          this.globals.isLoading = false;
          $('#upload_img_pdf').attr('src', 'assets/images/pdf-icon.png');
          $('#resume_upload_text').text(this.CandidateEntity.Resume);
          setTimeout(function () {
            $("#two").removeAttr('disabled');
            $('#Compalte1').addClass('completed');
            $("#three").removeAttr('disabled');
            $('#two').addClass('completed');
            $("#four").removeAttr('disabled');
            $('#three').addClass('completed');
            $("#five").removeAttr('disabled');
            $('#four').addClass('completed');
            $("#six").removeAttr('disabled');
            $('#five').addClass('completed');
            $("#seven").removeAttr('disabled');
            $('#six').addClass('completed');
            $("#eight").removeAttr('disabled');
            $('#seven').addClass('completed');
            $("#nine").removeAttr('disabled');
            $('#eight').addClass('completed');
            $("#headingEight").removeAttr('disabled');
            $('#nine').addClass('completed');


            $('.form_date').datetimepicker({
              weekStart: 1,
              todayBtn: 1,
              autoclose: 1,
              todayHighlight: 1,
              startView: 2,
              minView: 2,
              forceParse: 0,
              pickTime: false,
              format: 'mm/dd/yyyy',
            //  format: 'yyyy/mm/dd',
            });
            // $('.form_date').datetimepicker({
            //   weekStart: 1,
            //   //todayBtn: 1,
            //   autoclose: 1,
            //   todayHighlight: 1,
            //   startView: 2,
            //   minView: 2,
            //   format: 'mm/dd/yyyy',
            //   showMeridian: true,
            // });
          }, 100);
        },
          (error) => {
            //alert('error');
            this.btn_disable = false;
            this.submitted = false;
            this.globals.isLoading = false;
            //this.router.navigate(['/pagenotfound']);
          });
    }
    else {
      this.header = 'Add';
      this.globals.isLoading = false;
      this.submitbutton = 'Add';
      setTimeout(function()
      {  
        $('#full_time').addClass("width_long");
        $('#full_time').removeClass("width_small");
      },100);

    }
    
    $('.salary_block').hide();
    $('#full_time input').click(function () {
      $('.position_type_block .col-md-4').removeClass("width_long");
      $('.position_type_block .col-md-4').addClass("width_small");
      $('#full_time').addClass("width_long");
      $('#full_time').removeClass("width_small");
      $('#contract').removeClass("width_long");
      $('#part_time').removeClass("width_long");
      $('#contract').addClass("width_small");
      $('#part_time').addClass("width_small");
      $('.salary_block').hide();
      $("#full_time .salary_block").fadeIn(700);
    });
    $('#part_time input').click(function () {
      $('.position_type_block .col-md-4').removeClass("width_long");
      $('.position_type_block .col-md-4').addClass("width_small");
      $('#part_time').addClass("width_long");
      $('#part_time').removeClass("width_small");
      $('#contract').removeClass("width_long");
      $('#full_time').removeClass("width_long");
      $('#contract').addClass("width_small");
      $('#full_time').addClass("width_small");
      $('.salary_block').hide();
      $('#part_time .salary_block').fadeIn(700);
    });
    $('#contract input').click(function () {
      $('.position_type_block .col-md-4').removeClass("width_long");
      $('.position_type_block .col-md-4').addClass("width_small");
      $('#contract').addClass("width_long");
      $('#contract').removeClass("width_small");
      $('#part_time').removeClass("width_long");
      $('#full_time').removeClass("width_long");
      $('#part_time').addClass("width_small");
      $('#full_time').addClass("width_small");
      $('.salary_block').hide();
      $('#contract .salary_block').fadeIn(700);
    });
    new PerfectScrollbar('.content_height');

    $('input[type="radio"]').click(function () {
      var inputValue = $(this).attr("value");
      var targetBox = $("." + inputValue);
      $(".box").not(targetBox).css("display", "none");
      $(targetBox).css("display", "inline-block");
    });
    setTimeout(function () {
      $("#delete_file").click(function () {
        $("#resume_upload").val('');
        $('#upload_img_pdf').attr('src', 'assets/images/upload.png');
        $('#resume_upload_text').text('');
        $("#delete_file").hide();
      });
      $('.form_date').datetimepicker({
        weekStart: 1,
        todayBtn: 1,
        autoclose: 1,
        todayHighlight: 1,
        startView: 2,
        minView: 2,
        forceParse: 0,
        pickTime: false,
        format: 'mm/dd/yyyy',
      });

    }, 100);
    $("#resume_upload").change(function (event) {
      readURL(this);
    });
    function readURL(input) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        var filename = $("#resume_upload").val();
        filename = filename.substring(filename.lastIndexOf('\\') + 1);
        reader.onload = function (e) {
          $('#upload_img_pdf').attr('src', 'assets/images/pdf-icon.png');
          $('#resume_upload_text').text(filename);
        }
        reader.readAsDataURL(input.files[0]);
        $("#delete_file").show();
      }
    }
    $('input[type="radio"]').click(function () {
      var inputValue = $(this).attr("value");
      var targetBox = $("." + inputValue);
      $(".box").not(targetBox).css("display", "none");
      $(targetBox).css("display", "inline-block");
    });

  }
  // valueChanged() {
  //   if ($('.coupon_question').is(":checked"))
  //     $(".answer").hide();
  //   else
  //     $(".answer").show();
  // }
  addPersonalInformation(PersonalInformationForm) {
    debugger


    this.globals.isLoading = true;
    var count = 0;
    let id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.submitted = false;
    } else {
      this.submitted = true;
    }
    if (this.CandidateEntity.PositionType == 1) {
      if (this.CandidateEntity.FSalary != '' && this.CandidateEntity.FSalary != undefined && this.CandidateEntity.FSalary != null) {
        this.CandidateEntity.Salary = this.CandidateEntity.FSalary;
        this.salary_valid = false;
      }
      else {
        this.salary_valid = true;
        count = 1;
      }
    } else {
      this.salary_valid = false;
    }
    if (this.CandidateEntity.PositionType == 2) {
      if (this.CandidateEntity.PSalary != '' && this.CandidateEntity.PSalary != undefined && this.CandidateEntity.PSalary != null) {
        this.CandidateEntity.Salary = this.CandidateEntity.PSalary;
        this.salary1_valid = false;
      }
      else {
        this.salary1_valid = true;
        count = 1;
      }
    } else {
      this.salary1_valid = false;
    }
    if (this.CandidateEntity.PositionType == 3) {
      if (this.CandidateEntity.PayRate != '' && this.CandidateEntity.PayRate != undefined && this.CandidateEntity.PayRate != null) {
        this.CandidateEntity.Salary = this.CandidateEntity.PayRate;
        this.PayRate_valid = false;
      }
      else {
        this.PayRate_valid = true;
        count = 1;
      }
    } else {
      this.PayRate_valid = false;
    }
    if (PersonalInformationForm.valid && count == 0) {
      this.globals.isLoading = false;
      let id = this.route.snapshot.paramMap.get('id');
    if (id) 
    {
      setTimeout(function () {
        $("#collapseN").collapse('show');
        $("#two").removeAttr('disabled');
        $('#Compalte1').addClass('completed');
      }, 100);
      this.btn_disable = true;
      this.submitted = false;
      PersonalInformationForm.form.markAsPristine();
     }else{
      var ent = { 'EmailAddress': this.CandidateEntity.EmailAddress };
      this.CandidateFormService.Compareemail(ent)
        .then((data) => {
          if (data == 'email duplicate') {
            swal({
              type: 'warning',
              title: 'Oops!',
              text: 'This email is already exists!',
              showConfirmButton: false,
              timer: 1500
            })

            this.globals.isLoading = false;
          } else {
            setTimeout(function () {
              $("#collapseN").collapse('show');
              $("#two").removeAttr('disabled');
              $('#Compalte1').addClass('completed');
            }, 100);
            this.btn_disable = true;
            this.submitted = false;
            PersonalInformationForm.form.markAsPristine();
          }

        },
          (error) => {
            this.btn_disable = false;
            this.submitted2 = false;
            this.globals.isLoading = false;
          });
        }
      // setTimeout(function(){
      //         $("#collapseN").collapse('show');
      //         $("#two").removeAttr('disabled');
      //         $('#Compalte1').addClass('completed');
      //          },100);
      //          this.btn_disable = true;
      //          this.submitted = false;
      //          PersonalInformationForm.form.markAsPristine();
    } else {
      this.globals.isLoading = false;
    }

  }
  addSourceInformation(SourceInformationForm) {
    this.globals.isLoading = true;
    let id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.submittedSo = false;
    } else {
      this.submittedSo = true;
    }
    if (SourceInformationForm.valid) {
      setTimeout(function () {
        $("#collapseTwo").collapse('show');
        $("#three").removeAttr('disabled');
        $('#two').addClass('completed');
      }, 100);
      this.globals.isLoading = false;
      this.btn_disable = true;
      this.submittedSo = false;

      SourceInformationForm.form.markAsPristine();
    } else {
      this.globals.isLoading = false;
    }
  }
  addAvailability(AvailabilityForm) {
    this.globals.isLoading = true;
    let id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.submitted1 = false;
    } else {
      this.submitted1 = true;
    }
    if (AvailabilityForm.valid) {
      setTimeout(function () {
        $("#collapseThree").collapse('show');
        $("#four").removeAttr('disabled');
        $('#three').addClass('completed');
      }, 100);
      this.globals.isLoading = false;
      this.btn_disable = true;
      this.submitted1 = false;
      AvailabilityForm.form.markAsPristine();
    } else {
      this.globals.isLoading = false;
    }
  }
  addSkills(SkillsForm) {
    this.globals.isLoading = true;
    let id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.submitted2 = false;
    } else {
      this.submitted2 = true;
    }

    if (SkillsForm.valid) {
      setTimeout(function () {
        $("#collapseFour").collapse('show');
        $("#five").removeAttr('disabled');
        $('#four').addClass('completed');
      }, 100);
      this.btn_disable = true;
      this.submitted2 = false;
      this.globals.isLoading = false;
      SkillsForm.form.markAsPristine();
    } else {
      this.globals.isLoading = false;
    }
  }
  addtechskill(index) {
    var item = { 'Skill': '', 'ExperienceDuration': '0.5' };
    if (this.TechnicalSkillsEntity.length <= index + 1) {
      this.TechnicalSkillsEntity.splice(index + 1, 0, item);
    }
  }
  Removetechskill(id, index) {
    debugger
    swal({
      title: 'Delete a Technical Skill',
      text: "Are you sure you want to delete this technical skill?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: "No"
    })
      .then((result) => {
        if (result.value) {
          this.globals.isLoading = true;
          this.CandidateFormService.deleteTechnicalSkill(id)
            .then((data) => {

              this.btn_disable = false;
              this.TechnicalSkillsEntity.splice(index, 1);
              swal({

                type: 'success',
                title: 'Deleted!',
                text: 'Technical skill has been deleted successfully.',
                showConfirmButton: false,
                timer: 1500
              })
              this.globals.isLoading = false;
            },
              (error) => {
                this.btn_disable = false;
                this.submitted2 = false;
                this.globals.isLoading = false;
              });
        }
      })
    //  this.TechnicalSkillsEntity.splice(item, 1);
  }
  addsoftskill(index) {
    var item = { 'SSkill': '', 'SExperienceDuration': '0.5' };
    if (this.SoftSkillsEntity.length <= index + 1) {
      this.SoftSkillsEntity.splice(index + 1, 0, item);
    }
  }
  Removesoftskill(id, index) {
    debugger
    swal({
      title: 'Delete a Soft Skill',
      text: "Are you sure you want to delete this soft skill?",
      icon: "warning",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    })
      .then((result) => {
        if (result.value) {
          this.globals.isLoading = true;
          this.CandidateFormService.deleteTechnicalSkill(id)
            .then((data) => {

              this.btn_disable = false;
              //  this.submitted2 = false;
              this.SoftSkillsEntity.splice(index, 1);
              swal({

                type: 'success',
                title: 'Deleted!',
                text: 'Soft skill has been deleted successfully',
                showConfirmButton: false,
                timer: 1500
              })
              this.globals.isLoading = false;
            },
              (error) => {
                this.btn_disable = false;
                this.submitted2 = false;
                this.globals.isLoading = false;
              });
        }
      })
  }
  addQualification(QualificationForm) {
    this.globals.isLoading = true;
    let id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.submitted3 = false;
    } else {
      this.submitted3 = true;
    }
    if (QualificationForm.valid) {
      setTimeout(function () {
        $("#collapseFive").collapse('show');
        $("#six").removeAttr('disabled');
        $('#five').addClass('completed');
      }, 100);
      this.globals.isLoading = false;
      this.btn_disable = true;
      this.submitted3 = false;
      QualificationForm.form.markAsPristine();
    } else {
      this.globals.isLoading = false;
    }
  }
  addqualifi(index) {

    var item = { 'Institute': '', 'Location': '', 'Degree': '', 'PassingYear': '' };
    if (this.QualificationEntity.length <= index + 1) {
      this.QualificationEntity.splice(index + 1, 0, item);
    }
  }
  Removqualification(id, index) {
    swal({
      title: 'Delete a Qualification',
      text: "Are you sure you want to delete this qualification?",
      icon: "warning",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    })
      .then((result) => {
        if (result.value) {
          this.globals.isLoading = true;
          this.CandidateFormService.deletequalification(id)
            .then((data) => {
              this.btn_disable = false;
              this.QualificationEntity.splice(index, 1);
              swal({
                type: 'success',
                title: 'Deleted!',
                text: 'Qualification has been deleted successfully.',
                showConfirmButton: false,
                timer: 1500
              })
              this.globals.isLoading = false;
            },
              (error) => {
                this.globals.isLoading = false;
                this.btn_disable = false;
                this.submitted2 = false;
              });
        }
      })
  }
  addCertification(CertificationForm) {
    debugger
    this.globals.isLoading = true;
    for (var i = 0; i < this.CertificationEntity.length; i++) {
      this.CertificationEntity[i].EarnedDate = $("#EarnedDate" + i).val();

      if (this.CertificationEntity[i].EarnedDate == "" || this.CertificationEntity[i].EarnedDate == null || this.CertificationEntity[i].EarnedDate == undefined) {
        this.CertificationEntity[i].earneddate_valid = true;
      } else {
        this.CertificationEntity[i].earneddate_valid = false;
      }
    }
    let id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.submitted4 = false;
    } else {
      this.submitted4 = true;
    }


    if (CertificationForm.valid) {
      setTimeout(function () {
        $("#collapseSix").collapse('show');
        $("#seven").removeAttr('disabled');
        $('#six').addClass('completed');
      }, 100);
      this.btn_disable = true;
      this.submitted4 = false;
      this.globals.isLoading = false;
      CertificationForm.form.markAsPristine();
    } else {
      this.globals.isLoading = false;
    }
  }
  addCertificat(index) {
    var item = { 'Institute': '', 'CertificateName': '', 'CertificateNumber': '', 'EarnedDate': '' };
    if (this.CertificationEntity.length <= index + 1) {
      this.CertificationEntity.splice(index + 1, 0, item);
    }
    setTimeout(function () {

      $('.form_date').datetimepicker({
        weekStart: 1,
        todayBtn: 1,
        autoclose: 1,
        todayHighlight: 1,
        startView: 2,
        minView: 2,
        forceParse: 0,
        pickTime: false,
        format: 'mm/dd/yyyy',
      });
    }, 100);
  }
  RemovCertificat(id, index) {
    swal({
      title: 'Delete a Certificat',
      text: "Are you sure you want to delete this certificat?",
      icon: "warning",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    })
      .then((result) => {
        if (result.value) {
          this.globals.isLoading = true;
          this.CandidateFormService.deletecertificat(id)
            .then((data) => {
              this.btn_disable = false;
              this.CertificationEntity.splice(index, 1);
              swal({
                type: 'success',
                title: 'Deleted!',
                text: 'Certificat has been deleted successfully.',
                showConfirmButton: false,
                timer: 1500
              })
              this.globals.isLoading = false;
            },
              (error) => {
                this.btn_disable = false;
                this.submitted2 = false;
                this.globals.isLoading = false;
              });
        }
      })
  }

  addWorkExperience(WorkExperienceForm) {
    debugger
    this.globals.isLoading = true;
    for (var i = 0; i < this.WorkExperienceEntity.length; i++) {
      this.WorkExperienceEntity[i].StartDate = $("#StartDate" + i).val();
      if (this.WorkExperienceEntity[i].StartDate == "" || this.WorkExperienceEntity[i].StartDate == null || this.WorkExperienceEntity[i].StartDate == undefined) {
        this.WorkExperienceEntity[i].startdate_valid = true;
      } else {
        this.WorkExperienceEntity[i].startdate_valid = false;
      }
      if (this.WorkExperienceEntity[i].CurrentlyWorking == true) {
        this.WorkExperienceEntity[i].EndDate = null;
      } else {
        this.WorkExperienceEntity[i].EndDate = $("#EndDate" + i).val();
      }
    }
    let id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.submitted5 = false;
    } else {
      this.submitted5 = true;
    }


    if (WorkExperienceForm.valid) {
      setTimeout(function () {
        $("#collapseSeven").collapse('show');
        $("#eight").removeAttr('disabled');
        $('#seven').addClass('completed');
      }, 100);
      this.btn_disable = true;
      this.submitted5 = false;
      this.globals.isLoading = false;
      WorkExperienceForm.form.markAsPristine();
    } else {
      this.globals.isLoading = false;
    }
  }
  addWorkExpe(index) {
    var item = { 'CompanyName': '', 'Location': '', 'JobTitle': '', 'ReasonForLeaving': '', 'StartDate': '', 'EndDate': '', 'CurrentlyWorking': true };
    if (this.WorkExperienceEntity.length <= index + 1) {
      this.WorkExperienceEntity.splice(index + 1, 0, item);
    }
    setTimeout(function () {
      $('.form_date').datetimepicker({
        weekStart: 1,
        todayBtn: 1,
        autoclose: 1,
        todayHighlight: 1,
        startView: 2,
        minView: 2,
        forceParse: 0,
        pickTime: false,
        format: 'mm/dd/yyyy',
      });
    }, 100);
  }
  RemovWorkExperience(id, index) {
    swal({
      title: 'Delete a Work Experience',
      text: "Are you sure you want to delete this work experience?",
      icon: "warning",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    })
      .then((result) => {
        if (result.value) {
          this.globals.isLoading = true;
          this.CandidateFormService.deleteworkexperience(id)
            .then((data) => {
              this.btn_disable = false;
              this.WorkExperienceEntity.splice(index, 1);
              this.globals.isLoading = false;
              swal({
                type: 'success',
                title: 'Deleted!',
                text: 'Work experience has been deleted successfully.',
                showConfirmButton: false,
                timer: 1500
              })
              this.globals.isLoading = false;
            },
              (error) => {
                this.btn_disable = false;
                this.globals.isLoading = false;
              });
        }
      })
  }
  finelsubmit(PersonalInformationForm,SourceInformationForm, AvailabilityForm, SkillsForm, QualificationForm, WorkExperienceForm, CommentForm)
  //finelsubmit(CommentForm)
  {
    debugger
    this.globals.isLoading = true;
    var count=0;
    let file2 = this.elem.nativeElement.querySelector('#resume_upload').files[0];
    var fd = new FormData();
    if (file2) {
      let ResumeName = file2['name'];
      var Resume = Date.now() + '_' + ResumeName;

      fd.append('Resume', file2, Resume);
      var fileExtension = ResumeName.substr((ResumeName.lastIndexOf('.') + 1));

      this.CandidateEntity.Resume = Resume;
    }
    else {
      fd.append('Resume', null);
      this.CandidateEntity.Resume = null;
    }

    let id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.submitted6 = false;
      if(this.CandidateEntity.Resume==null)
      {
        count=0;
      }else{
       if (fileExtension != 'pdf') {
        count=1;
        this.PdfValid = true;
        setTimeout(function () {
          swal({
            type: 'warning',
            title: 'Oops!',
            text: 'Please select PDF file!',
          })
        }, 100);
       }else
        {  
         count=0;
        this.PdfValid = false;
       }
     }
    } else {
      this.submitted6 = true;
    }
    // if(this.WorkExperienceEntity.)
    // EndDate
    for (var i = 0; i < this.WorkExperienceEntity.length; i++)
    {
      if(this.WorkExperienceEntity[i].EndDate == "" || this.WorkExperienceEntity[i].EndDate == null || this.WorkExperienceEntity[i].EndDate == undefined)
       {
        this.WorkExperienceEntity[i].EndDate=null;
       }else
       {
        this.WorkExperienceEntity[i].EndDate;
       }
    }
    this.CandidateEntity.UserId = this.globals.authData.UserId;
    //  if (PersonalInformationForm.valid && SourceInformationForm.valid && AvailabilityForm.valid && SkillsForm.valid && QualificationForm.valid 
    //   && ResumeForm.valid && WorkExperienceForm.valid && CommentForm.valid)
    if (PersonalInformationForm.valid && SourceInformationForm.valid && AvailabilityForm.valid && SkillsForm.valid && QualificationForm.valid
      && WorkExperienceForm.valid && CommentForm.valid && count==0) {

      this.globals.isLoading = true;
      var add = {
        'Candidate': this.CandidateEntity,
        'TechnicalSkill': this.TechnicalSkillsEntity,
        'SoftSkills': this.SoftSkillsEntity,
        'Qualification': this.QualificationEntity,
        'Certification': this.CertificationEntity,
        'WorkExperience': this.WorkExperienceEntity
      };
      this.CandidateFormService.add(add)
        .then((data) => {
          if (file2) {
            this.CandidateFormService.uploadFile(fd, this.globals.authData.UserId)
              .then((data) => {
                debugger

                this.btn_disable = true;
                this.submitted6 = false;
                this.globals.isLoading = false;
              }, (error) => {
                this.btn_disable = false;
                this.submitted6 = false;
                this.globals.isLoading = false;
                this.router.navigate(['/pagenotfound']);
              });
          }    
          let id = this.route.snapshot.paramMap.get('id');
             if (id) 
              {
                swal({
                  type: 'success',
                  title: 'Updated!',
                  text: 'Candidate has been updated successfully',
                  showConfirmButton: false,
                  timer: 3000
                 
                })
               }else{
                swal({
                  type: 'success',
                  title: 'Success...!',
                  text: 'Candidate has been added successfully.',
                  showConfirmButton: false,
                  timer: 3000
                })
               }
      
          this.submitted6 = false;
          this.btn_disable = true;
          CommentForm.form.markAsPristine();
          this.router.navigate(['/candidate-list']);
        },
          (error) => {
            this.globals.isLoading = false;
            this.btn_disable = false;
            this.submitted = false;
          });

    } else {
      swal({
        type: 'warning',
        icon: "warning",
        title: 'Oops!',
        text: 'Please Enter input!',
      })
      
      this.globals.isLoading = false;
    }

  }
  Resume(ResumeForm) {
    debugger
    this.globals.isLoading = true;
    var count = 0;
    let file2 = this.elem.nativeElement.querySelector('#resume_upload').files[0];
    var fd = new FormData();
    if (file2) {
      let ResumeName = file2['name'];
      var Resume = Date.now() + '_' + ResumeName;

      fd.append('Resume', file2, Resume);
      var fileExtension = ResumeName.substr((ResumeName.lastIndexOf('.') + 1));

      this.CandidateEntity.Resume = Resume;
    }
    else {
      fd.append('Resume', null);
      this.CandidateEntity.Resume = null;
    }
    this.submitted7 = true;

    let id = this.route.snapshot.paramMap.get('id');
    if (id) {
      if(fileExtension != 'pdf')
      {
        count = 1;
        this.PdfValid = true;
        
      }else
      {
        count = 0;
        this.PdfValid = false;
      }
    
    } else {
      if (fileExtension != 'pdf' || this.CandidateEntity.Resume == "" || this.CandidateEntity.Resume == null || this.CandidateEntity.Resume == undefined) {
        this.PdfValid = true;
        count = 1;
      } else {
        this.PdfValid = false;
      }
    }
    if (ResumeForm.valid && count == 0 ) {
      setTimeout(function () {
        $("#collapseEight").collapse('show');
        $("#nine").removeAttr('disabled');
        $('#eight').addClass('completed');
      }, 100);
      this.btn_disable = true;
      this.submitted7 = false;
      this.globals.isLoading = false;
      ResumeForm.form.markAsPristine();
    } else {
      this.globals.isLoading = false;
    }
  }
}
