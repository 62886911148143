import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '.././globals';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient } from "@angular/common/http";
@Injectable()
export class CandidateFormService {

  constructor(private http: HttpClient, private globals: Globals, private router: Router) { }
  add(Entity) {
    debugger
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.globals.baseAPIUrl + 'Recruiter/addCandidate', Entity)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
            //this.globals.isLoading = false;
            // this.router.navigate(['/pagenotfound']);
          }
        );
    });
    return promise;
  }
  uploadFile(file, id) {
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.globals.baseAPIUrl + 'Recruiter/uploadFile/' + id, file)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
            this.globals.isLoading = false;
            this.router.navigate(['/pagenotfound']);
          }
        );
    });
    return promise;
  }
  getAllCandidate(){
	  debugger
	let promise = new Promise((resolve, reject) => {
    this.http.get(this.globals.baseAPIUrl + 'Recruiter/getAllCandidate')
      .toPromise()
      .then(
        res => { // Success
          resolve(res);
        },
        msg => { // Error
      reject(msg);
 //  this.globals.isLoading = false;
      //this.router.navigate(['/pagenotfound']);
        }
      );
	});		
	return promise;
  }
 
  getById(id){
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.globals.baseAPIUrl + 'Recruiter/getById/' + id)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
        reject(msg);
      //  this.globals.isLoading = false;
        this.router.navigate(['/pagenotfound']);
          }
        );
    });		
    return promise;
    }
    deleteCandidate(del) {
      debugger
      let promise = new Promise((resolve, reject) => {
        this.http.post(this.globals.baseAPIUrl + 'Recruiter/deleteCandidate', del)
          .toPromise()
          .then(
            res => { // Success
              resolve(res);
            },
            msg => { // Error
              reject(msg);
              //  this.globals.isLoading = false;
              this.router.navigate(['/pagenotfound']);
            }
          );
      });
      return promise;
    }
    deleteTechnicalSkill(del) {
      debugger
      let promise = new Promise((resolve, reject) => {
        this.http.post(this.globals.baseAPIUrl + 'Recruiter/deleteTechnicalSkill', del)
          .toPromise()
          .then(
            res => { // Success
              resolve(res);
            },
            msg => { // Error
              reject(msg);
              //  this.globals.isLoading = false;
              this.router.navigate(['/pagenotfound']);
            }
          );
      });
      return promise;
    }
    deletequalification(del) {
      debugger
      let promise = new Promise((resolve, reject) => {
        this.http.post(this.globals.baseAPIUrl + 'Recruiter/deletequalification', del)
          .toPromise()
          .then(
            res => { // Success
              resolve(res);
            },
            msg => { // Error
              reject(msg);
              //  this.globals.isLoading = false;
              this.router.navigate(['/pagenotfound']);
            }
          );
      });
      return promise;
    }
    deletecertificat(del) {
      debugger
      let promise = new Promise((resolve, reject) => {
        this.http.post(this.globals.baseAPIUrl + 'Recruiter/deletecertificate', del)
          .toPromise()
          .then(
            res => { // Success
              resolve(res);
            },
            msg => { // Error
              reject(msg);
              //  this.globals.isLoading = false;
              this.router.navigate(['/pagenotfound']);
            }
          );
      });
      return promise;
    }
    deleteworkexperience(del) {
      debugger
      let promise = new Promise((resolve, reject) => {
        this.http.post(this.globals.baseAPIUrl + 'Recruiter/deleteworkexperience', del)
          .toPromise()
          .then(
            res => { // Success
              resolve(res);
            },
            msg => { // Error
              reject(msg);
              //  this.globals.isLoading = false;
              this.router.navigate(['/pagenotfound']);
            }
          );
      });
      return promise;
    }

  addclient(Entity) {
      debugger
      let promise = new Promise((resolve, reject) => {
        this.http.post(this.globals.baseAPIUrl + 'Recruiter/addClient', Entity)
          .toPromise()
          .then(
            res => { // Success
              resolve(res);
            },
            msg => { // Error
              reject(msg);
              //this.globals.isLoading = false;
              // this.router.navigate(['/pagenotfound']);
            }
          );
      });
      return promise;
  }
  addclientemail(Entity) {
    debugger
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.globals.baseAPIUrl + 'Recruiter/addclientemail', Entity)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
            //this.globals.isLoading = false;
            // this.router.navigate(['/pagenotfound']);
          }
        );
    });
    return promise;
}

addclientsingle(Entity) {
  debugger
  let promise = new Promise((resolve, reject) => {
    this.http.post(this.globals.baseAPIUrl + 'Recruiter/addclientsingle', Entity)
      .toPromise()
      .then(
        res => { // Success
          resolve(res);
        },
        msg => { // Error
          reject(msg);
          //this.globals.isLoading = false;
          // this.router.navigate(['/pagenotfound']);
        }
      );
  });
  return promise;
}
getAllClient(id){
  let promise = new Promise((resolve, reject) => {
    this.http.get(this.globals.baseAPIUrl + 'Recruiter/getAllClient/' + id)
      .toPromise()
      .then(
        res => { // Success
          resolve(res);
        },
        msg => { // Error
      reject(msg);
    //  this.globals.isLoading = false;
      this.router.navigate(['/pagenotfound']);
        }
      );
  });		
  return promise;
  }
 
    Compareemail(Entity) {
      debugger
      let promise = new Promise((resolve, reject) => {
        this.http.post(this.globals.baseAPIUrl + 'Recruiter/Compareemail', Entity)
          .toPromise()
          .then(
            res => { // Success
              resolve(res);
            },
            msg => { // Error
              reject(msg);
              //this.globals.isLoading = false;
              // this.router.navigate(['/pagenotfound']);
            }
          );
      });
      return promise;
    }
    CandidatePdf(id){
      let promise = new Promise((resolve, reject) => {
        this.http.get(this.globals.baseAPIUrl + 'Recruiter/CandidatePdf/' + id)
          .toPromise()
          .then(
            res => { // Success
              resolve(res);
            },
            msg => { // Error
          reject(msg);
        //  this.globals.isLoading = false;
          this.router.navigate(['/pagenotfound']);
            }
          );
      });		
      return promise;
      }
}
